<template functional>
	<svg
		viewBox="0 0 369.3 144"
		:class="[data.class, data.staticClass]"
		:style="[data.style, data.staticStyle]"
		:fill="props.fill"
	>
		<title>NCUA Logo</title>
		<g>
			<path
				d="M131.6,104.2h-14.1L94.8,64.7h-0.3c0.5,7,0.7,12,0.7,14.9v24.6h-9.9V52h14l22.7,39.1h0.3
                c-0.4-6.8-0.5-11.6-0.5-14.4V52h10V104.2z M166.9,60.5c-4.2,0-7.4,1.6-9.7,4.7c-2.3,3.1-3.4,7.5-3.4,13.1
                c0,11.6,4.4,17.5,13.1,17.5c3.7,0,8.1-0.9,13.3-2.8v9.3c-4.3,1.8-9.1,2.7-14.4,2.7c-7.6,0-13.4-2.3-17.4-6.9c-4-4.6-6-11.2-6-19.8
                c0-5.4,1-10.2,3-14.3c2-4.1,4.8-7.2,8.5-9.4c3.7-2.2,8-3.3,13-3.3c5.1,0,10.2,1.2,15.3,3.7l-3.6,9c-2-0.9-3.9-1.7-5.9-2.4
                C170.8,60.8,168.8,60.5,166.9,60.5z M233.9,52v33.8c0,3.9-0.9,7.2-2.6,10.1c-1.7,2.9-4.2,5.1-7.5,6.7c-3.3,1.5-7.1,2.3-11.6,2.3
                c-6.7,0-11.9-1.7-15.6-5.2c-3.7-3.4-5.6-8.1-5.6-14.1V52h11v32c0,4,0.8,7,2.4,8.9s4.3,2.8,8,2.8c3.6,0,6.2-0.9,7.9-2.8
                c1.6-1.9,2.4-4.9,2.4-8.9V52H233.9z M278.6,104.2l-3.8-12.4h-19l-3.8,12.4h-11.9l18.4-52.4h13.5l18.5,52.4H278.6z M272.2,82.5
                c-3.5-11.3-5.5-17.6-5.9-19.1s-0.8-2.6-0.9-3.5c-0.8,3-3,10.6-6.8,22.6H272.2z"
			/>
			<path
				d="M28.1,19.7l2.1-3.9h1l-2.6,4.8v3h-0.9v-3L25,15.8h1L28.1,19.7z M37.1,20.7c0,1-0.2,1.7-0.7,2.2
                c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8
                c0.8,0,1.5,0.3,1.9,0.8C36.9,19,37.1,19.8,37.1,20.7z M32.7,20.7c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6
                c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7s-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6
                C32.8,19.4,32.7,19.9,32.7,20.7z M39.5,17.8v3.8c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.6,0.4,1,0.4c0.6,0,1.1-0.2,1.3-0.5
                c0.3-0.3,0.4-0.9,0.4-1.6v-3.1h0.9v5.8h-0.7l-0.1-0.8h0c-0.2,0.3-0.4,0.5-0.8,0.7c-0.3,0.2-0.7,0.2-1.1,0.2c-0.7,0-1.2-0.2-1.6-0.5
                c-0.4-0.3-0.5-0.9-0.5-1.6v-3.8H39.5z M48.1,17.7c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1c-0.5,0-0.9,0.2-1.2,0.6
                s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9S47.7,17.7,48.1,17.7z M56.5,22c0,0.5-0.2,1-0.6,1.3
                c-0.4,0.3-1,0.4-1.7,0.4c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,0.8-0.1,1.1-0.2
                c0.2-0.1,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5c-0.2-0.2-0.4-0.3-0.5-0.5
                c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.5,0.2-0.9,0.6-1.1c0.4-0.3,0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4L56,18.8c-0.6-0.2-1.1-0.4-1.6-0.4
                c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,1,0.4
                c0.7,0.3,1.2,0.5,1.4,0.8C56.3,21.3,56.5,21.6,56.5,22z M61.5,23.6l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2
                c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3
                c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4
                H61.5z M59.5,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9
                c0,0.3,0.1,0.6,0.3,0.7C58.9,22.9,59.1,23,59.5,23z M65.2,23.6L63,17.8H64l1.3,3.5c0.3,0.8,0.5,1.3,0.5,1.6h0
                c0-0.2,0.2-0.6,0.4-1.2c0.2-0.6,0.7-1.9,1.4-3.9h0.9l-2.2,5.8H65.2z M69.4,16.2c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1
                c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
                S69.4,16.4,69.4,16.2z M70.3,23.6h-0.9v-5.8h0.9V23.6z M76.2,23.6v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H76.2z M83.7,17.8v0.6l-1.1,0.1c0.1,0.1,0.2,0.3,0.3,0.5
                c0.1,0.2,0.1,0.4,0.1,0.7c0,0.6-0.2,1-0.6,1.4c-0.4,0.3-0.9,0.5-1.6,0.5c-0.2,0-0.3,0-0.5,0c-0.4,0.2-0.6,0.4-0.6,0.8
                c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.4,0.1,0.7,0.1h1c0.6,0,1.1,0.1,1.5,0.4c0.3,0.3,0.5,0.7,0.5,1.2c0,0.6-0.3,1.1-0.8,1.5
                c-0.5,0.3-1.3,0.5-2.3,0.5c-0.8,0-1.4-0.1-1.8-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.4,0.1-0.7,0.3-0.9s0.5-0.4,1-0.5
                c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.6s0.3-0.3,0.5-0.5c-0.3-0.1-0.5-0.3-0.7-0.6
                c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1.1,0.6-1.5c0.4-0.3,0.9-0.5,1.6-0.5c0.3,0,0.6,0,0.8,0.1H83.7z M79,24.6c0,0.3,0.1,0.6,0.4,0.7
                c0.3,0.2,0.6,0.2,1.1,0.2c0.7,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.1-0.6-0.2-1.1-0.2h-1.1
                c-0.4,0-0.7,0.1-0.9,0.3C79.1,24,79,24.3,79,24.6z M79.5,19.7c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.3,1,0.3c0.9,0,1.3-0.4,1.3-1.3
                c0-0.9-0.4-1.3-1.3-1.3c-0.4,0-0.7,0.1-1,0.3C79.6,18.9,79.5,19.2,79.5,19.7z M88.7,22c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1,0.4-1.7,0.4
                c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,0.8-0.1,1.1-0.2c0.2-0.1,0.4-0.4,0.4-0.7
                c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7
                c0-0.5,0.2-0.9,0.6-1.1c0.4-0.3,0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.3,0.7c-0.6-0.2-1.1-0.4-1.6-0.4c-0.4,0-0.7,0.1-0.9,0.2
                c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,1,0.4c0.7,0.3,1.2,0.5,1.4,0.8
                C88.5,21.3,88.7,21.6,88.7,22z M95.6,18.5h-1.5v5.2h-0.9v-5.2h-1v-0.4l1-0.3v-0.3c0-1.4,0.6-2.2,1.9-2.2c0.3,0,0.7,0.1,1.1,0.2
                l-0.2,0.7c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.1v0.4h1.5V18.5z M99.1,23.7
                c-0.9,0-1.5-0.3-2-0.8s-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7
                c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8
                c-0.3,0.1-0.6,0.2-0.9,0.3C99.8,23.7,99.5,23.7,99.1,23.7z M98.9,18.4c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1
                c0-0.6-0.1-1-0.4-1.3C99.7,18.6,99.3,18.4,98.9,18.4z M106.7,22.8L106.7,22.8c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8
                s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L106.7,22.8
                z M105,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5
                c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C104,22.8,104.4,23,105,23z M111.9,23.7c-0.9,0-1.5-0.3-2-0.8
                s-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4
                c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3
                C112.7,23.7,112.3,23.7,111.9,23.7z M111.7,18.4c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3
                C112.5,18.6,112.1,18.4,111.7,18.4z M118.2,17.7c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1c-0.5,0-0.9,0.2-1.2,0.6
                s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9S117.9,17.7,118.2,17.7z M123.6,23.6l-0.2-0.8h0
                c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0
                v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1
                c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H123.6z M121.6,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0
                c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7C121,22.9,121.3,23,121.6,23z M127,23.6h-0.9v-8.3h0.9
                V23.6z M129.8,23.6h-0.9v-8.3h0.9V23.6z M130.7,17.8h0.9l1.3,3.3c0.3,0.8,0.5,1.3,0.5,1.6h0c0-0.2,0.1-0.5,0.3-0.9
                c0.1-0.4,0.6-1.8,1.4-4h0.9l-2.5,6.7c-0.2,0.7-0.5,1.1-0.9,1.4c-0.3,0.3-0.7,0.4-1.2,0.4c-0.3,0-0.5,0-0.8-0.1v-0.7
                c0.2,0,0.4,0.1,0.7,0.1c0.6,0,1-0.3,1.3-1l0.3-0.8L130.7,17.8z M139.9,16.2c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1
                c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
                S139.9,16.4,139.9,16.2z M140.9,23.6H140v-5.8h0.9V23.6z M146.7,23.6v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H146.7z M153.2,22c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1,0.4-1.7,0.4
                c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,0.8-0.1,1.1-0.2c0.2-0.1,0.4-0.4,0.4-0.7
                c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7
                c0-0.5,0.2-0.9,0.6-1.1c0.4-0.3,0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.3,0.7c-0.6-0.2-1.1-0.4-1.6-0.4c-0.4,0-0.7,0.1-0.9,0.2
                c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,1,0.4c0.7,0.3,1.2,0.5,1.4,0.8
                C153.1,21.3,153.2,21.6,153.2,22z M155.5,17.8v3.8c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.6,0.4,1,0.4c0.6,0,1.1-0.2,1.3-0.5
                c0.3-0.3,0.4-0.9,0.4-1.6v-3.1h0.9v5.8h-0.7l-0.1-0.8h0c-0.2,0.3-0.4,0.5-0.8,0.7c-0.3,0.2-0.7,0.2-1.1,0.2c-0.7,0-1.2-0.2-1.6-0.5
                c-0.4-0.3-0.5-0.9-0.5-1.6v-3.8H155.5z M164,17.7c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1c-0.5,0-0.9,0.2-1.2,0.6
                s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9S163.6,17.7,164,17.7z M168.3,23.7c-0.9,0-1.5-0.3-2-0.8
                s-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4
                c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3
                C169,23.7,168.7,23.7,168.3,23.7z M168,18.4c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3
                C168.9,18.6,168.5,18.4,168,18.4z M175.9,22.8L175.9,22.8c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8s-0.6-1.3-0.6-2.2
                c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3H176L175.9,22.8z M174.1,23
                c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.6
                s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C173.2,22.8,173.6,23,174.1,23z M183.3,23c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8V18l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4
                c0,0.4,0.1,0.6,0.3,0.8S183.1,23,183.3,23z M190.4,20.7c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4
                c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.7,0.7-2.2c0.5-0.5,1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8
                S190.4,19.8,190.4,20.7z M185.9,20.7c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6s1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7
                c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6C186.1,19.4,185.9,19.9,185.9,20.7z M198.3,23.6l-0.2-0.8h0
                c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0
                v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4s0.7-0.1,1-0.1
                c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H198.3z M196.3,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0
                c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7S196,23,196.3,23z M202.7,23c0.2,0,0.3,0,0.5,0
                c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1s-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5H200V18l0.8-0.4l0.4-1.2h0.5v1.4h1.7
                v0.7h-1.7v3.4c0,0.4,0.1,0.6,0.3,0.8S202.4,23,202.7,23z M208.4,23.6h-0.9v-8.3h0.9V23.6z M212.7,23.7c-0.9,0-1.5-0.3-2-0.8
                c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4
                c0,0.7,0.2,1.2,0.5,1.6s0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3C213.5,23.7,213.1,23.7,212.7,23.7z
                M212.5,18.4c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C213.3,18.6,213,18.4,212.5,18.4z M220,23.6
                l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3
                c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7
                c0.3-0.2,0.6-0.3,0.9-0.4s0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H220z M218,23c0.6,0,1-0.2,1.3-0.5
                c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7S217.6,23,218,23z
                M226.2,22c0,0.5-0.2,1-0.6,1.3s-1,0.4-1.7,0.4c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1
                c0.5,0,0.8-0.1,1.1-0.2c0.2-0.1,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5
                s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.5,0.2-0.9,0.6-1.1s0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.3,0.7
                c-0.6-0.2-1.1-0.4-1.6-0.4c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3
                s0.5,0.2,1,0.4c0.7,0.3,1.2,0.5,1.4,0.8C226.1,21.3,226.2,21.6,226.2,22z M229.6,23c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8V18l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4
                c0,0.4,0.1,0.6,0.3,0.8S229.3,23,229.6,23z M239,21.2c0,0.5-0.2,0.9-0.5,1.2s-0.9,0.5-1.5,0.6v1.2h-0.7v-1.2c-0.4,0-0.8,0-1.2-0.1
                c-0.4-0.1-0.7-0.1-0.9-0.3v-0.8c0.3,0.1,0.6,0.2,1,0.3c0.4,0.1,0.7,0.1,1.1,0.1V20c-0.7-0.2-1.2-0.5-1.5-0.8
                c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.2-0.8,0.5-1.1c0.4-0.3,0.8-0.5,1.4-0.5v-1h0.7v1c0.7,0,1.3,0.1,1.9,0.4l-0.3,0.7
                c-0.5-0.2-1.1-0.3-1.6-0.4v2.3c0.6,0.2,1,0.4,1.3,0.5s0.5,0.4,0.6,0.6C238.9,20.7,239,20.9,239,21.2z M235.1,18
                c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.8,0.5V17c-0.4,0.1-0.6,0.2-0.8,0.3C235.2,17.5,235.1,17.7,235.1,18z M238.1,21.3
                c0-0.3-0.1-0.5-0.2-0.6s-0.5-0.3-0.9-0.5v2.1C237.7,22.2,238.1,21.9,238.1,21.3z M245.3,23.6h-5.1v-0.8l2.1-2.1
                c0.6-0.6,1-1.1,1.2-1.4c0.2-0.3,0.3-0.5,0.4-0.8s0.1-0.5,0.1-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.2-0.6-0.4-1-0.4
                c-0.3,0-0.6,0.1-0.9,0.2s-0.6,0.3-1,0.6l-0.5-0.6c0.7-0.6,1.5-0.9,2.3-0.9c0.7,0,1.3,0.2,1.7,0.6c0.4,0.4,0.6,0.9,0.6,1.5
                c0,0.5-0.1,1-0.4,1.5s-0.8,1.1-1.6,1.8l-1.7,1.7v0h4V23.6z M248.9,18.9c0.8,0,1.5,0.2,1.9,0.6c0.5,0.4,0.7,1,0.7,1.7
                c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.5-1.2,0.7-2.1,0.7c-0.9,0-1.5-0.1-2-0.4v-0.9c0.2,0.2,0.6,0.3,0.9,0.4c0.4,0.1,0.7,0.1,1.1,0.1
                c0.6,0,1.1-0.1,1.5-0.4s0.5-0.7,0.5-1.3c0-1.1-0.7-1.6-2-1.6c-0.3,0-0.8,0.1-1.4,0.2l-0.5-0.3l0.3-3.6h3.9v0.8h-3.1l-0.2,2.3
                C248.1,18.9,248.5,18.9,248.9,18.9z M257.9,19.7c0,1.3-0.2,2.4-0.6,3c-0.4,0.7-1.1,1-1.9,1c-0.8,0-1.5-0.3-1.9-1
                c-0.4-0.7-0.7-1.7-0.7-3c0-1.4,0.2-2.4,0.6-3s1.1-1,1.9-1c0.8,0,1.5,0.3,1.9,1C257.6,17.4,257.9,18.4,257.9,19.7z M253.6,19.7
                c0,1.1,0.1,2,0.4,2.5s0.7,0.8,1.3,0.8c0.6,0,1-0.3,1.3-0.8s0.4-1.3,0.4-2.5c0-1.1-0.1-1.9-0.4-2.5s-0.7-0.8-1.3-0.8
                c-0.6,0-1,0.3-1.3,0.8S253.6,18.6,253.6,19.7z M260.3,22.4l0.1,0.1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.5-0.4,0.9-0.5,1.3h-0.7
                c0.1-0.4,0.2-0.8,0.3-1.4c0.1-0.5,0.2-1,0.2-1.3H260.3z M266.8,19.7c0,1.3-0.2,2.4-0.6,3c-0.4,0.7-1.1,1-1.9,1
                c-0.8,0-1.5-0.3-1.9-1c-0.4-0.7-0.7-1.7-0.7-3c0-1.4,0.2-2.4,0.6-3c0.4-0.7,1.1-1,1.9-1c0.8,0,1.5,0.3,1.9,1
                C266.6,17.4,266.8,18.4,266.8,19.7z M262.5,19.7c0,1.1,0.1,2,0.4,2.5s0.7,0.8,1.3,0.8c0.6,0,1-0.3,1.3-0.8c0.3-0.5,0.4-1.3,0.4-2.5
                c0-1.1-0.1-1.9-0.4-2.5c-0.3-0.5-0.7-0.8-1.3-0.8c-0.6,0-1,0.3-1.3,0.8S262.5,18.6,262.5,19.7z M273,19.7c0,1.3-0.2,2.4-0.6,3
                c-0.4,0.7-1.1,1-1.9,1c-0.8,0-1.5-0.3-1.9-1c-0.4-0.7-0.7-1.7-0.7-3c0-1.4,0.2-2.4,0.6-3s1.1-1,1.9-1c0.8,0,1.5,0.3,1.9,1
                C272.8,17.4,273,18.4,273,19.7z M268.8,19.7c0,1.1,0.1,2,0.4,2.5s0.7,0.8,1.3,0.8c0.6,0,1-0.3,1.3-0.8s0.4-1.3,0.4-2.5
                c0-1.1-0.1-1.9-0.4-2.5s-0.7-0.8-1.3-0.8c-0.6,0-1,0.3-1.3,0.8S268.8,18.6,268.8,19.7z M279.3,19.7c0,1.3-0.2,2.4-0.6,3
                c-0.4,0.7-1.1,1-1.9,1c-0.8,0-1.5-0.3-1.9-1c-0.4-0.7-0.7-1.7-0.7-3c0-1.4,0.2-2.4,0.6-3c0.4-0.7,1.1-1,1.9-1c0.8,0,1.5,0.3,1.9,1
                C279.1,17.4,279.3,18.4,279.3,19.7z M275,19.7c0,1.1,0.1,2,0.4,2.5s0.7,0.8,1.3,0.8c0.6,0,1-0.3,1.3-0.8c0.3-0.5,0.4-1.3,0.4-2.5
                c0-1.1-0.1-1.9-0.4-2.5c-0.3-0.5-0.7-0.8-1.3-0.8c-0.6,0-1,0.3-1.3,0.8S275,18.6,275,19.7z M287.2,23.6l-0.2-0.8h0
                c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0
                v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4s0.7-0.1,1-0.1
                c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H287.2z M285.2,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0
                c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7S284.8,23,285.2,23z M293.7,23.6v-3.8
                c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0
                c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H293.7z M300.3,22.8L300.3,22.8
                c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8
                c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L300.3,22.8z M298.6,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6
                v-0.2c0-0.8-0.1-1.4-0.4-1.8s-0.7-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7
                C297.6,22.8,298,23,298.6,23z M308.6,17.7c0.8,0,1.4,0.3,1.8,0.8s0.6,1.3,0.6,2.2c0,1-0.2,1.7-0.6,2.2c-0.4,0.5-1,0.8-1.8,0.8
                c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.4-0.8-0.6h-0.1l-0.2,0.8h-0.6v-8.3h0.9v2c0,0.5,0,0.9,0,1.2h0
                C307.2,18,307.8,17.7,308.6,17.7z M308.5,18.4c-0.6,0-1,0.2-1.3,0.5s-0.4,0.9-0.4,1.8c0,0.8,0.1,1.4,0.4,1.8s0.7,0.5,1.3,0.5
                c0.5,0,0.9-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.7c0-0.8-0.1-1.3-0.4-1.7S309,18.4,308.5,18.4z M316.2,23.6l-0.2-0.8h0
                c-0.3,0.4-0.6,0.6-0.9,0.7s-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4
                c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1
                c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H316.2z M314.2,23c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0
                c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7C313.6,22.9,313.8,23,314.2,23z M321,23.7
                c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-1,0.2-1.7,0.7-2.3s1.1-0.8,2-0.8c0.3,0,0.6,0,0.8,0.1c0.3,0.1,0.5,0.1,0.7,0.2
                l-0.3,0.8c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-1.2,0-1.8,0.8-1.8,2.3c0,0.7,0.1,1.3,0.4,1.7
                c0.3,0.4,0.7,0.6,1.3,0.6c0.5,0,1-0.1,1.5-0.3v0.8C322.1,23.6,321.6,23.7,321,23.7z M324.7,20.6c0.2-0.2,0.4-0.5,0.7-0.9l1.9-2h1
                l-2.4,2.5l2.5,3.4h-1.1l-2.1-2.8l-0.7,0.6v2.2h-0.9v-8.3h0.9v4.4C324.7,19.9,324.7,20.2,324.7,20.6L324.7,20.6z M332.1,23.7
                c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7
                c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6s0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3
                C332.8,23.7,332.5,23.7,332.1,23.7z M331.8,18.4c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3
                C332.6,18.6,332.3,18.4,331.8,18.4z M339.7,22.8L339.7,22.8c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8s-0.6-1.3-0.6-2.2
                c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L339.7,22.8z M337.9,23
                c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8s-0.7-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7
                c0,0.7,0.1,1.3,0.4,1.7C337,22.8,337.4,23,337.9,23z M37.8,30.8c0.8,0,1.4,0.3,1.8,0.8s0.6,1.3,0.6,2.2c0,1-0.2,1.7-0.6,2.2
                s-1,0.8-1.8,0.8c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.4-0.8-0.6h-0.1l-0.2,0.8H35v-8.3h0.9v2c0,0.5,0,0.9,0,1.2h0
                C36.3,31.1,36.9,30.8,37.8,30.8z M37.6,31.5c-0.6,0-1,0.2-1.3,0.5s-0.4,0.9-0.4,1.8c0,0.8,0.1,1.4,0.4,1.8c0.3,0.4,0.7,0.5,1.3,0.5
                c0.5,0,0.9-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.7c0-0.8-0.1-1.3-0.4-1.7S38.2,31.5,37.6,31.5z M40.8,30.9h0.9l1.3,3.3
                c0.3,0.8,0.5,1.3,0.5,1.6h0c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.6-1.8,1.4-4h0.9l-2.5,6.7c-0.2,0.7-0.5,1.1-0.9,1.4
                c-0.3,0.3-0.7,0.4-1.2,0.4c-0.3,0-0.5,0-0.8-0.1v-0.7c0.2,0,0.4,0.1,0.7,0.1c0.6,0,1-0.3,1.3-1l0.3-0.8L40.8,30.9z M52,36.1
                c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4
                l0.8-0.4l0.4-1.2H51v1.4h1.7v0.7H51V35c0,0.4,0.1,0.6,0.3,0.8S51.7,36.1,52,36.1z M57.9,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1
                s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5s-0.4,0.9-0.4,1.6v3.1h-0.9v-8.3h0.9v2.5c0,0.3,0,0.6,0,0.8h0.1c0.2-0.3,0.4-0.5,0.7-0.7
                s0.7-0.2,1.1-0.2c0.7,0,1.3,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H57.9z M63.1,36.8c-0.9,0-1.5-0.3-2-0.8s-0.7-1.3-0.7-2.2
                c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6
                c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3C63.8,36.8,63.5,36.8,63.1,36.8z M62.9,31.5
                c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C63.7,31.7,63.3,31.5,62.9,31.5z M72.2,31.6h-1.5v5.2
                h-0.9v-5.2h-1v-0.4l1-0.3v-0.3c0-1.4,0.6-2.2,1.9-2.2c0.3,0,0.7,0.1,1.1,0.2l-0.2,0.7c-0.3-0.1-0.6-0.2-0.9-0.2
                c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.1v0.4h1.5V31.6z M74.1,30.9v3.8c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.6,0.4,1,0.4
                c0.6,0,1.1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-3.1h0.9v5.8h-0.7l-0.1-0.8h0c-0.2,0.3-0.4,0.5-0.8,0.7c-0.3,0.2-0.7,0.2-1.1,0.2
                c-0.7,0-1.2-0.2-1.6-0.5c-0.4-0.3-0.5-0.9-0.5-1.6v-3.8H74.1z M80.9,36.7H80v-8.3h0.9V36.7z M83.6,36.7h-0.9v-8.3h0.9V36.7z
                M91,31.6h-1.5v5.2h-0.9v-5.2h-1v-0.4l1-0.3v-0.3c0-1.4,0.6-2.2,1.9-2.2c0.3,0,0.7,0.1,1.1,0.2l-0.2,0.7c-0.3-0.1-0.6-0.2-0.9-0.2
                c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.1v0.4H91V31.6z M95.6,36.7l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7
                c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1
                c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5
                c0.3,0.3,0.5,0.8,0.5,1.5v4H95.6z M93.6,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3
                c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7C93,36,93.3,36.1,93.6,36.1z M98.1,29.3c0-0.2,0-0.4,0.1-0.4
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.1,0-0.3,0-0.4-0.1S98.1,29.5,98.1,29.3z M99,36.7h-0.9v-5.8H99V36.7z M102.8,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7V35
                c0,0.4,0.1,0.6,0.3,0.8S102.5,36.1,102.8,36.1z M108.7,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5
                s-0.4,0.9-0.4,1.6v3.1h-0.9v-8.3h0.9v2.5c0,0.3,0,0.6,0,0.8h0.1c0.2-0.3,0.4-0.5,0.7-0.7s0.7-0.2,1.1-0.2c0.7,0,1.3,0.2,1.6,0.5
                c0.4,0.3,0.5,0.9,0.5,1.6v3.8H108.7z M117.9,36.7l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2
                c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3
                c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4
                H117.9z M115.9,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9
                c0,0.3,0.1,0.6,0.3,0.7C115.3,36,115.5,36.1,115.9,36.1z M124.4,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H124.4z M131,35.9L131,35.9c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8
                s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L131,35.9z
                M129.3,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5
                c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C128.3,35.9,128.7,36.1,129.3,36.1z M138.9,36.8
                c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-1,0.2-1.7,0.7-2.3c0.5-0.5,1.1-0.8,2-0.8c0.3,0,0.6,0,0.8,0.1
                c0.3,0.1,0.5,0.1,0.7,0.2l-0.3,0.8c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-1.2,0-1.8,0.8-1.8,2.3
                c0,0.7,0.1,1.3,0.4,1.7s0.7,0.6,1.3,0.6c0.5,0,1-0.1,1.5-0.3v0.8C140,36.7,139.5,36.8,138.9,36.8z M144.5,30.8c0.3,0,0.5,0,0.7,0.1
                l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1c-0.5,0-0.9,0.2-1.2,0.6s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9
                S144.1,30.8,144.5,30.8z M148.7,36.8c-0.9,0-1.5-0.3-2-0.8s-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8
                c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8
                c-0.3,0.1-0.6,0.2-0.9,0.3C149.4,36.8,149.1,36.8,148.7,36.8z M148.5,31.5c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1
                c0-0.6-0.1-1-0.4-1.3C149.3,31.7,148.9,31.5,148.5,31.5z M156.3,35.9L156.3,35.9c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8
                s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L156.3,35.9
                z M154.6,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5
                c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C153.6,35.9,154,36.1,154.6,36.1z M159,29.3c0-0.2,0-0.4,0.1-0.4
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.1,0-0.3,0-0.4-0.1S159,29.5,159,29.3z M159.9,36.7h-0.9v-5.8h0.9V36.7z M163.7,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7V35
                c0,0.4,0.1,0.6,0.3,0.8S163.4,36.1,163.7,36.1z M173.6,33.8c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4
                c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8
                C173.3,32.1,173.6,32.9,173.6,33.8z M169.1,33.8c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6c0.6,0,1-0.2,1.3-0.6
                c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7s-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6C169.3,32.5,169.1,33,169.1,33.8z M177.7,31.6
                h-1.5v5.2h-0.9v-5.2h-1v-0.4l1-0.3v-0.3c0-1.4,0.6-2.2,1.9-2.2c0.3,0,0.7,0.1,1.1,0.2l-0.2,0.7c-0.3-0.1-0.6-0.2-0.9-0.2
                c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.1v0.4h1.5V31.6z M183.5,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7V35
                c0,0.4,0.1,0.6,0.3,0.8S183.2,36.1,183.5,36.1z M189.5,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5
                c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-8.3h0.9v2.5c0,0.3,0,0.6,0,0.8h0.1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.7-0.2,1.1-0.2
                c0.7,0,1.3,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H189.5z M194.7,36.8c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2
                c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6
                c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3S195.1,36.8,194.7,36.8z M194.4,31.5
                c-0.5,0-0.8,0.2-1.1,0.5s-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C195.3,31.7,194.9,31.5,194.4,31.5z M207.2,28.9v5
                c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.3,0.8-2.2,0.8s-1.7-0.3-2.2-0.8s-0.8-1.2-0.8-2.1v-5h0.9V34c0,0.7,0.2,1.2,0.5,1.5
                c0.4,0.3,0.9,0.5,1.6,0.5c0.7,0,1.2-0.2,1.5-0.5c0.4-0.4,0.5-0.9,0.5-1.5v-5.1H207.2z M213.1,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1
                s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7
                s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H213.1z M215.7,29.3c0-0.2,0-0.4,0.1-0.4
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.1,0-0.3,0-0.4-0.1S215.7,29.5,215.7,29.3z M216.7,36.7h-0.9v-5.8h0.9V36.7z M220.5,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1
                v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7V35
                c0,0.4,0.1,0.6,0.3,0.8S220.2,36.1,220.5,36.1z M224.9,36.8c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2
                c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5
                c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3S225.3,36.8,224.9,36.8z M224.7,31.5c-0.5,0-0.8,0.2-1.1,0.5s-0.4,0.7-0.5,1.3
                h3.1c0-0.6-0.1-1-0.4-1.3C225.5,31.7,225.1,31.5,224.7,31.5z M232.5,35.9L232.5,35.9c-0.5,0.6-1.1,0.9-1.9,0.9
                c-0.8,0-1.4-0.3-1.8-0.8s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2s1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3
                h-0.7L232.5,35.9z M230.8,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5
                c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C229.8,35.9,230.2,36.1,230.8,36.1z M242.6,34.7
                c0,0.7-0.2,1.2-0.7,1.6c-0.5,0.4-1.2,0.6-2,0.6c-0.9,0-1.6-0.1-2.1-0.4v-0.9c0.3,0.1,0.7,0.2,1,0.3c0.4,0.1,0.8,0.1,1.1,0.1
                c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.5s-0.6-0.3-1.2-0.5
                c-0.7-0.3-1.2-0.6-1.6-0.9c-0.3-0.4-0.5-0.8-0.5-1.4c0-0.6,0.2-1.1,0.7-1.4c0.5-0.4,1-0.5,1.8-0.5c0.8,0,1.5,0.1,2.1,0.4l-0.3,0.8
                c-0.6-0.3-1.3-0.4-1.9-0.4c-0.5,0-0.9,0.1-1.1,0.3c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.3,0.3,0.5,0.5
                c0.2,0.1,0.6,0.3,1.1,0.5c0.8,0.3,1.4,0.6,1.7,0.9C242.5,33.7,242.6,34.1,242.6,34.7z M246,36.1c0.2,0,0.3,0,0.5,0
                c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5
                v1.4h1.7v0.7H245V35c0,0.4,0.1,0.6,0.3,0.8S245.7,36.1,246,36.1z M251.5,36.7l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7
                c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1
                c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4s0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5
                c0.3,0.3,0.5,0.8,0.5,1.5v4H251.5z M249.5,36.1c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3
                c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7S249.2,36.1,249.5,36.1z M255.9,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7
                c-0.1,0-0.2,0.1-0.4,0.1s-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7H255V35
                c0,0.4,0.1,0.6,0.3,0.8S255.6,36.1,255.9,36.1z M260.3,36.8c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2
                c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6s0.8,0.5,1.4,0.5
                c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3C261.1,36.8,260.7,36.8,260.3,36.8z M260.1,31.5c-0.5,0-0.8,0.2-1.1,0.5
                c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C260.9,31.7,260.6,31.5,260.1,31.5z M267.8,35.1c0,0.5-0.2,1-0.6,1.3
                s-1,0.4-1.7,0.4c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,0.8-0.1,1.1-0.2
                c0.2-0.1,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5s-0.4-0.3-0.5-0.5
                c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.5,0.2-0.9,0.6-1.1s0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.3,0.7c-0.6-0.2-1.1-0.4-1.6-0.4
                c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,1,0.4
                c0.7,0.3,1.2,0.5,1.4,0.8C267.6,34.4,267.8,34.7,267.8,35.1z M275.6,32.6h2.7v3.8c-0.4,0.1-0.8,0.2-1.3,0.3
                c-0.4,0.1-0.9,0.1-1.5,0.1c-1.2,0-2.1-0.4-2.8-1.1c-0.7-0.7-1-1.7-1-3c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.4-1.4
                c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.6,0.2,2.3,0.5l-0.4,0.8c-0.7-0.3-1.4-0.4-2-0.4c-0.9,0-1.7,0.3-2.2,0.8s-0.8,1.3-0.8,2.4
                c0,1.1,0.3,1.8,0.8,2.4s1.3,0.8,2.3,0.8c0.5,0,1.1-0.1,1.6-0.2v-2.4h-1.7V32.6z M285,33.8c0,1-0.2,1.7-0.7,2.2
                c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8
                c0.8,0,1.5,0.3,1.9,0.8C284.8,32.1,285,32.9,285,33.8z M280.6,33.8c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6
                c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6
                C280.7,32.5,280.6,33,280.6,33.8z M287.9,36.7l-2.2-5.8h0.9l1.3,3.5c0.3,0.8,0.5,1.3,0.5,1.6h0c0-0.2,0.2-0.6,0.4-1.2
                c0.2-0.6,0.7-1.9,1.4-3.9h0.9l-2.2,5.8H287.9z M294.5,36.8c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2
                c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5
                c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3S294.9,36.8,294.5,36.8z M294.3,31.5c-0.5,0-0.8,0.2-1.1,0.5s-0.4,0.7-0.5,1.3
                h3.1c0-0.6-0.1-1-0.4-1.3C295.1,31.7,294.7,31.5,294.3,31.5z M300.9,30.8c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1
                c-0.5,0-0.9,0.2-1.2,0.6s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7L299,32h0c0.2-0.4,0.5-0.7,0.8-0.9S300.5,30.8,300.9,30.8z M306.6,36.7
                v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0
                c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H306.6z M316.8,36.7v-3.8
                c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.5v3.3h-0.9v-3.8c0-0.5-0.1-0.8-0.3-1
                c-0.2-0.2-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5s-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.7-0.7
                c0.3-0.2,0.6-0.2,1-0.2c0.9,0,1.5,0.3,1.8,1h0c0.2-0.3,0.4-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.7,0,1.2,0.2,1.5,0.5
                c0.3,0.3,0.5,0.9,0.5,1.6v3.8H316.8z M322,36.8c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2
                c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9V34h-4c0,0.7,0.2,1.2,0.5,1.6s0.8,0.5,1.4,0.5
                c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3C322.7,36.8,322.4,36.8,322,36.8z M321.7,31.5c-0.5,0-0.8,0.2-1.1,0.5
                c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C322.5,31.7,322.2,31.5,321.7,31.5z M329.6,36.7v-3.8c0-0.5-0.1-0.8-0.3-1.1
                s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7
                s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H329.6z M334.2,36.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1
                v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8v-3.5h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7V35
                c0,0.4,0.1,0.6,0.3,0.8S333.9,36.1,334.2,36.1z"
			/>
			<path
				d="M27.4,123.1h-1l-4.3-6.5h0c0.1,0.8,0.1,1.5,0.1,2.1v4.4h-0.8v-7.8h1l4.2,6.5h0c0-0.1,0-0.4,0-0.9
                c0-0.5,0-0.9,0-1.1v-4.5h0.8V123.1z M33,123.1l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4
                c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4
                l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H33z M31,122.5
                c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7
                C30.3,122.4,30.6,122.5,31,122.5z M37.3,122.5c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1
                c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8V118h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4c0,0.4,0.1,0.6,0.3,0.8
                S37,122.5,37.3,122.5z M39.2,115.7c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4
                s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1S39.2,115.9,39.2,115.7z M40.2,123.1h-0.9v-5.8h0.9V123.1z
                M47.1,120.2c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6
                c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8C46.9,118.5,47.1,119.3,47.1,120.2z M42.6,120.2c0,0.7,0.1,1.3,0.4,1.7
                c0.3,0.4,0.7,0.6,1.3,0.6c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7s-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6
                C42.8,118.9,42.6,119.5,42.6,120.2z M52.6,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5
                c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5
                c0.4,0.3,0.5,0.9,0.5,1.6v3.8H52.6z M58.9,123.1l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2
                c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3
                c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4
                H58.9z M56.9,122.5c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9
                c0,0.3,0.1,0.6,0.3,0.7C56.3,122.4,56.6,122.5,56.9,122.5z M62.3,123.1h-0.9v-8.3h0.9V123.1z M70.5,116c-0.9,0-1.5,0.3-2,0.9
                c-0.5,0.6-0.7,1.4-0.7,2.3c0,1,0.2,1.8,0.7,2.4c0.5,0.6,1.2,0.8,2,0.8c0.5,0,1.2-0.1,1.9-0.3v0.8c-0.5,0.2-1.2,0.3-2,0.3
                c-1.1,0-2-0.3-2.7-1c-0.6-0.7-0.9-1.7-0.9-3c0-0.8,0.1-1.5,0.5-2.1c0.3-0.6,0.7-1.1,1.3-1.4s1.2-0.5,2-0.5c0.8,0,1.5,0.1,2.1,0.4
                l-0.4,0.8C71.7,116.2,71.1,116,70.5,116z M76.6,117.2c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1
                c-0.5,0-0.9,0.2-1.2,0.6s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9S76.2,117.2,76.6,117.2z
                M80.8,123.2c-0.9,0-1.5-0.3-2-0.8s-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7
                c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8
                c-0.3,0.1-0.6,0.2-0.9,0.3C81.6,123.2,81.2,123.2,80.8,123.2z M80.6,117.9c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3H82
                c0-0.6-0.1-1-0.4-1.3C81.4,118.1,81.1,117.9,80.6,117.9z M88.5,122.4L88.5,122.4c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8
                s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L88.5,122.4
                z M86.7,122.5c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5
                c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7c0,0.7,0.1,1.3,0.4,1.7C85.8,122.3,86.2,122.5,86.7,122.5z M91.1,115.7c0-0.2,0-0.4,0.1-0.4
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.1,0-0.3,0-0.4-0.1S91.1,115.9,91.1,115.7z M92.1,123.1h-0.9v-5.8h0.9V123.1z M95.8,122.5c0.2,0,0.3,0,0.5,0
                c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8V118h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5
                v1.4h1.7v0.7h-1.7v3.4c0,0.4,0.1,0.6,0.3,0.8S95.5,122.5,95.8,122.5z M106.7,115.3v5c0,0.9-0.3,1.6-0.8,2.1
                c-0.5,0.5-1.3,0.8-2.2,0.8s-1.7-0.3-2.2-0.8s-0.8-1.2-0.8-2.1v-5h0.9v5.1c0,0.7,0.2,1.2,0.5,1.5c0.4,0.3,0.9,0.5,1.6,0.5
                c0.7,0,1.2-0.2,1.5-0.5c0.4-0.4,0.5-0.9,0.5-1.5v-5.1H106.7z M112.6,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H112.6z M115.2,115.7c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1
                c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
                S115.2,115.9,115.2,115.7z M116.2,123.1h-0.9v-5.8h0.9V123.1z M123.1,120.2c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8
                c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8
                C122.9,118.5,123.1,119.3,123.1,120.2z M118.6,120.2c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6c0.6,0,1-0.2,1.3-0.6
                c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7s-0.7-0.6-1.3-0.6c-0.6,0-1,0.2-1.3,0.6C118.8,118.9,118.6,119.5,118.6,120.2z
                M128.6,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7
                l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H128.6z M139.2,123.1
                l-1-2.5h-3.1l-1,2.5h-0.9l3.1-7.8h0.8l3.1,7.8H139.2z M138,119.8l-0.9-2.4c-0.1-0.3-0.2-0.7-0.4-1.1c-0.1,0.3-0.2,0.7-0.3,1.1
                l-0.9,2.4H138z M145.1,122.4L145.1,122.4c-0.5,0.6-1.1,0.9-1.9,0.9c-0.8,0-1.4-0.3-1.8-0.8s-0.6-1.3-0.6-2.2c0-1,0.2-1.7,0.6-2.2
                c0.4-0.5,1-0.8,1.8-0.8c0.8,0,1.4,0.3,1.8,0.9h0.1l0-0.4l0-0.4v-2.4h0.9v8.3h-0.7L145.1,122.4z M143.3,122.5c0.6,0,1-0.2,1.3-0.5
                c0.3-0.3,0.4-0.9,0.4-1.6v-0.2c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.6s-0.4,1-0.4,1.7
                c0,0.7,0.1,1.3,0.4,1.7C142.4,122.3,142.8,122.5,143.3,122.5z M155.2,123.1v-3.8c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3
                c-0.6,0-1,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.5v3.3h-0.9v-3.8c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5
                s-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.2,1-0.2c0.9,0,1.5,0.3,1.8,1h0
                c0.2-0.3,0.4-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.9,0.5,1.6v3.8H155.2z M157.9,115.7
                c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4
                c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1S157.9,115.9,157.9,115.7z M158.8,123.1h-0.9v-5.8h0.9V123.1z M164.7,123.1v-3.8
                c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0
                c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H164.7z M167.3,115.7
                c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4
                c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1S167.3,115.9,167.3,115.7z M168.3,123.1h-0.9v-5.8h0.9V123.1z M173.9,121.5
                c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1,0.4-1.7,0.4c-0.8,0-1.4-0.1-1.8-0.4v-0.8c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0.1,0.9,0.1
                c0.5,0,0.8-0.1,1.1-0.2c0.2-0.1,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.3-0.6s-0.6-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.2-0.5
                c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.5,0.2-0.9,0.6-1.1c0.4-0.3,0.9-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4
                l-0.3,0.7c-0.6-0.2-1.1-0.4-1.6-0.4c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.3
                s0.5,0.2,1,0.4c0.7,0.3,1.2,0.5,1.4,0.8C173.8,120.8,173.9,121.2,173.9,121.5z M177.3,122.5c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1
                v0.7c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8V118h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4
                c0,0.4,0.1,0.6,0.3,0.8S177,122.5,177.3,122.5z M181.9,117.2c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1
                c-0.5,0-0.9,0.2-1.2,0.6s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0c0.2-0.4,0.5-0.7,0.8-0.9S181.5,117.2,181.9,117.2z
                M187.3,123.1l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3
                c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7
                c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v4H187.3z M185.3,122.5
                c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7
                C184.7,122.4,184.9,122.5,185.3,122.5z M191.7,122.5c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1
                s-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8V118H189v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4c0,0.4,0.1,0.6,0.3,0.8
                S191.4,122.5,191.7,122.5z M193.5,115.7c0-0.2,0-0.4,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1s0.2,0.2,0.2,0.4
                s-0.1,0.3-0.2,0.4s-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1S193.5,115.9,193.5,115.7z M194.5,123.1h-0.9v-5.8h0.9V123.1z
                M201.4,120.2c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6
                c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8C201.2,118.5,201.4,119.3,201.4,120.2z M197,120.2c0,0.7,0.1,1.3,0.4,1.7
                c0.3,0.4,0.7,0.6,1.3,0.6c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6
                c-0.6,0-1,0.2-1.3,0.6C197.1,118.9,197,119.5,197,120.2z M207,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1H203v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H207z M210.6,121.9l0.1,0.1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.5-0.4,0.9-0.5,1.3
                h-0.7c0.1-0.4,0.2-0.8,0.3-1.4c0.1-0.5,0.2-1,0.2-1.3H210.6z M218.8,123.1l-0.2-0.8h0c-0.3,0.4-0.6,0.6-0.9,0.7
                c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.4-0.4c-0.3-0.3-0.5-0.7-0.5-1.3c0-1.2,0.9-1.8,2.8-1.9l1,0v-0.4c0-0.5-0.1-0.8-0.3-1
                c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-1,0.1-1.7,0.4l-0.3-0.7c0.3-0.2,0.6-0.3,0.9-0.4s0.7-0.1,1-0.1c0.7,0,1.2,0.2,1.5,0.5
                c0.3,0.3,0.5,0.8,0.5,1.5v4H218.8z M216.8,122.5c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.7,0.5-1.3v-0.5l-0.9,0c-0.7,0-1.2,0.1-1.5,0.3
                c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.6,0.3,0.7S216.4,122.5,216.8,122.5z M230.1,115.3v5c0,0.9-0.3,1.6-0.8,2.1
                c-0.5,0.5-1.3,0.8-2.2,0.8s-1.7-0.3-2.2-0.8s-0.8-1.2-0.8-2.1v-5h0.9v5.1c0,0.7,0.2,1.2,0.5,1.5c0.4,0.3,0.9,0.5,1.6,0.5
                c0.7,0,1.2-0.2,1.5-0.5c0.4-0.4,0.5-0.9,0.5-1.5v-5.1H230.1z M231.9,122.6c0-0.2,0.1-0.4,0.2-0.5s0.3-0.2,0.5-0.2
                c0.2,0,0.4,0.1,0.5,0.2s0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2
                C232,123,231.9,122.8,231.9,122.6z M239.5,121.1c0,0.7-0.2,1.2-0.7,1.6c-0.5,0.4-1.2,0.6-2,0.6c-0.9,0-1.6-0.1-2.1-0.4V122
                c0.3,0.1,0.7,0.2,1,0.3s0.8,0.1,1.1,0.1c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.5-0.2-0.7
                c-0.1-0.2-0.3-0.3-0.5-0.5c-0.3-0.1-0.6-0.3-1.2-0.5c-0.7-0.3-1.2-0.6-1.6-0.9c-0.3-0.4-0.5-0.8-0.5-1.4c0-0.6,0.2-1.1,0.7-1.4
                s1-0.5,1.8-0.5c0.8,0,1.5,0.1,2.1,0.4l-0.3,0.8c-0.6-0.3-1.3-0.4-1.9-0.4c-0.5,0-0.9,0.1-1.1,0.3c-0.3,0.2-0.4,0.5-0.4,0.9
                c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.6,0.3,1.1,0.5c0.8,0.3,1.4,0.6,1.7,0.9S239.5,120.5,239.5,121.1z
                M240.8,122.6c0-0.2,0.1-0.4,0.2-0.5s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5
                c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2C240.9,123,240.8,122.8,240.8,122.6z M250.3,119.1h2.7v3.8
                c-0.4,0.1-0.8,0.2-1.3,0.3c-0.4,0.1-0.9,0.1-1.5,0.1c-1.2,0-2.1-0.4-2.8-1.1c-0.7-0.7-1-1.7-1-3c0-0.8,0.2-1.5,0.5-2.1
                c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.6,0.2,2.3,0.5l-0.4,0.8c-0.7-0.3-1.4-0.4-2-0.4c-0.9,0-1.7,0.3-2.2,0.8
                s-0.8,1.3-0.8,2.4c0,1.1,0.3,1.8,0.8,2.4s1.3,0.8,2.3,0.8c0.5,0,1.1-0.1,1.6-0.2v-2.4h-1.7V119.1z M259.7,120.2
                c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.1,0.8-2,0.8c-0.5,0-1-0.1-1.4-0.4c-0.4-0.2-0.7-0.6-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6
                c0-1,0.2-1.7,0.7-2.2s1.1-0.8,2-0.8c0.8,0,1.5,0.3,1.9,0.8S259.7,119.3,259.7,120.2z M255.2,120.2c0,0.7,0.1,1.3,0.4,1.7
                c0.3,0.4,0.7,0.6,1.3,0.6s1-0.2,1.3-0.6c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6
                c-0.6,0-1,0.2-1.3,0.6C255.4,118.9,255.2,119.5,255.2,120.2z M262.5,123.1l-2.2-5.8h0.9l1.3,3.5c0.3,0.8,0.5,1.3,0.5,1.6h0
                c0-0.2,0.2-0.6,0.4-1.2c0.2-0.6,0.7-1.9,1.4-3.9h0.9l-2.2,5.8H262.5z M269.2,123.2c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2
                c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6
                s0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8c-0.3,0.1-0.6,0.2-0.9,0.3C269.9,123.2,269.6,123.2,269.2,123.2z M268.9,117.9
                c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3C269.8,118.1,269.4,117.9,268.9,117.9z M275.5,117.2
                c0.3,0,0.5,0,0.7,0.1l-0.1,0.8c-0.2-0.1-0.5-0.1-0.6-0.1c-0.5,0-0.9,0.2-1.2,0.6s-0.5,0.9-0.5,1.4v3.1h-0.9v-5.8h0.7l0.1,1.1h0
                c0.2-0.4,0.5-0.7,0.8-0.9S275.1,117.2,275.5,117.2z M281.3,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4
                c-0.6,0-1.1,0.2-1.3,0.5c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2
                c0.7,0,1.2,0.2,1.6,0.5c0.4,0.3,0.5,0.9,0.5,1.6v3.8H281.3z M291.5,123.1v-3.8c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3
                c-0.6,0-1,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.5v3.3h-0.9v-3.8c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5
                s-0.4,0.9-0.4,1.6v3.1H284v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.2,1-0.2c0.9,0,1.5,0.3,1.8,1h0
                c0.2-0.3,0.4-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.7,0,1.2,0.2,1.5,0.5c0.3,0.3,0.5,0.9,0.5,1.6v3.8H291.5z M296.6,123.2
                c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7
                c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8
                c-0.3,0.1-0.6,0.2-0.9,0.3S297,123.2,296.6,123.2z M296.4,117.9c-0.5,0-0.8,0.2-1.1,0.5s-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3
                C297.2,118.1,296.9,117.9,296.4,117.9z M304.3,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5
                c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5
                c0.4,0.3,0.5,0.9,0.5,1.6v3.8H304.3z M308.9,122.5c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.3-0.1v0.7c-0.1,0-0.2,0.1-0.4,0.1
                c-0.2,0-0.4,0-0.5,0c-1.1,0-1.7-0.6-1.7-1.8V118h-0.8v-0.4l0.8-0.4l0.4-1.2h0.5v1.4h1.7v0.7h-1.7v3.4c0,0.4,0.1,0.6,0.3,0.8
                S308.6,122.5,308.9,122.5z M318.7,123.1l-1-2.5h-3.1l-1,2.5h-0.9l3.1-7.8h0.8l3.1,7.8H318.7z M317.5,119.8l-0.9-2.4
                c-0.1-0.3-0.2-0.7-0.4-1.1c-0.1,0.3-0.2,0.7-0.3,1.1l-0.9,2.4H317.5z M325.4,117.3v0.6l-1.1,0.1c0.1,0.1,0.2,0.3,0.3,0.5
                c0.1,0.2,0.1,0.4,0.1,0.7c0,0.6-0.2,1-0.6,1.4c-0.4,0.3-0.9,0.5-1.6,0.5c-0.2,0-0.3,0-0.5,0c-0.4,0.2-0.6,0.4-0.6,0.8
                c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.4,0.1,0.7,0.1h1c0.6,0,1.1,0.1,1.5,0.4s0.5,0.7,0.5,1.2c0,0.6-0.3,1.1-0.8,1.5
                c-0.5,0.3-1.3,0.5-2.3,0.5c-0.8,0-1.4-0.1-1.8-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.4,0.1-0.7,0.3-0.9s0.5-0.4,1-0.5
                c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.5c-0.3-0.1-0.5-0.3-0.7-0.6
                c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1.1,0.6-1.5c0.4-0.3,0.9-0.5,1.6-0.5c0.3,0,0.6,0,0.8,0.1H325.4z M320.7,124.1
                c0,0.3,0.1,0.6,0.4,0.7c0.3,0.2,0.6,0.2,1.1,0.2c0.7,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.3-0.7
                c-0.2-0.1-0.6-0.2-1.1-0.2H322c-0.4,0-0.7,0.1-0.9,0.3C320.8,123.5,320.7,123.8,320.7,124.1z M321.2,119.2c0,0.4,0.1,0.7,0.3,0.9
                c0.2,0.2,0.6,0.3,1,0.3c0.9,0,1.3-0.4,1.3-1.3c0-0.9-0.4-1.3-1.3-1.3c-0.4,0-0.7,0.1-1,0.3C321.3,118.4,321.2,118.7,321.2,119.2z
                M329,123.2c-0.9,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-0.9,0.2-1.7,0.7-2.2c0.5-0.6,1.1-0.8,1.9-0.8c0.7,0,1.3,0.2,1.7,0.7
                c0.4,0.5,0.6,1.1,0.6,1.9v0.6h-4c0,0.7,0.2,1.2,0.5,1.6c0.3,0.4,0.8,0.5,1.4,0.5c0.6,0,1.3-0.1,1.9-0.4v0.8
                c-0.3,0.1-0.6,0.2-0.9,0.3S329.4,123.2,329,123.2z M328.8,117.9c-0.5,0-0.8,0.2-1.1,0.5s-0.4,0.7-0.5,1.3h3.1c0-0.6-0.1-1-0.4-1.3
                C329.6,118.1,329.3,117.9,328.8,117.9z M336.7,123.1v-3.8c0-0.5-0.1-0.8-0.3-1.1s-0.6-0.4-1-0.4c-0.6,0-1.1,0.2-1.3,0.5
                c-0.3,0.3-0.4,0.9-0.4,1.6v3.1h-0.9v-5.8h0.7l0.1,0.8h0c0.2-0.3,0.4-0.5,0.8-0.7s0.7-0.2,1.1-0.2c0.7,0,1.2,0.2,1.6,0.5
                c0.4,0.3,0.5,0.9,0.5,1.6v3.8H336.7z M341.7,123.2c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.7-1.3-0.7-2.2c0-1,0.2-1.7,0.7-2.3
                c0.5-0.5,1.1-0.8,2-0.8c0.3,0,0.6,0,0.8,0.1c0.3,0.1,0.5,0.1,0.7,0.2l-0.3,0.8c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.1
                c-1.2,0-1.8,0.8-1.8,2.3c0,0.7,0.1,1.3,0.4,1.7c0.3,0.4,0.7,0.6,1.3,0.6c0.5,0,1-0.1,1.5-0.3v0.8
                C342.8,123.1,342.3,123.2,341.7,123.2z M343.7,117.3h0.9l1.3,3.3c0.3,0.8,0.5,1.3,0.5,1.6h0c0-0.2,0.1-0.5,0.3-0.9
                c0.1-0.4,0.6-1.8,1.4-4h0.9l-2.5,6.7c-0.2,0.7-0.5,1.1-0.9,1.4s-0.7,0.4-1.2,0.4c-0.3,0-0.5,0-0.8-0.1V125c0.2,0,0.4,0.1,0.7,0.1
                c0.6,0,1-0.3,1.3-1l0.3-0.8L343.7,117.3z"
			/>
			<path d="M369.3,144H0V0h369.3V144z M6,138h357.3V6H6V138z" />
		</g>
	</svg>
</template>





<script>
export default {
	name: 'NCUALogo',
	props: {
		fill: {
			type: String,
			required: false,
			default: 'currentColor'
		}
	}
};
</script>