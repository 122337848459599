<template functional>
	<svg
		viewBox="0 0 200 200"
		:class="[data.class, data.staticClass]"
		:style="[data.style, data.staticStyle]"
		:fill="props.fill"
	>
		<g>
			<polygon
				points="34.4,151.6 27.8,151.6 27.8,154.2 33.9,154.2 33.9,156.5 27.8,156.5 27.8,159.7 34.7,159.7 
		34.7,161.9 25.2,161.9 25.2,149.4 34.4,149.4 34.4,151.6 	"
			/>
			<path
				d="M42.8,158.5l1.3,1.2c-0.5,0.2-1,0.3-1.4,0.3c-1.4,0-3.4-0.9-3.4-4.4c0-3.5,2-4.4,3.4-4.4
            c1.4,0,3.4,0.9,3.4,4.4c0,1.2-0.2,2-0.6,2.7l-1.3-1.2L42.8,158.5L42.8,158.5z M48.8,161.4l-1.4-1.3c0.8-1,1.3-2.4,1.3-4.5
            c0-5.9-4.4-6.6-6-6.6c-1.7,0-6,0.7-6,6.6c0,5.9,4.4,6.6,6,6.6c0.7,0,2-0.1,3.2-0.8l1.5,1.4L48.8,161.4L48.8,161.4z"
			/>
			<path
				d="M61.1,157.6c0,3.3-2,4.7-5.2,4.7c-1.2,0-2.9-0.3-4-1.5c-0.7-0.8-0.9-1.8-1-3v-8.3h2.7v8.1c0,1.8,1,2.5,2.2,2.5
            c1.8,0,2.5-0.9,2.5-2.4v-8.3h2.7V157.6L61.1,157.6z"
			/>
			<path
				d="M68.1,152.2L68.1,152.2l1.6,5h-3.2L68.1,152.2L68.1,152.2z M65.8,159.3h4.6l0.8,2.6H74l-4.4-12.6h-3l-4.5,12.6
            h2.8L65.8,159.3L65.8,159.3z"
			/>
			<polygon points="78.7,159.7 85,159.7 85,161.9 76.1,161.9 76.1,149.4 78.7,149.4 78.7,159.7 	" />
			<polygon
				points="94.9,156.3 94.9,161.9 92.3,161.9 92.3,149.4 94.9,149.4 94.9,154.1 99.8,154.1 99.8,149.4 
            102.4,149.4 102.4,161.9 99.8,161.9 99.8,156.3 94.9,156.3 	"
			/>
			<path
				d="M107.4,155.7c0-3.5,2-4.4,3.4-4.4c1.4,0,3.4,0.9,3.4,4.4c0,3.5-2,4.4-3.4,4.4
            C109.4,160,107.4,159.2,107.4,155.7L107.4,155.7z M104.8,155.7c0,5.9,4.4,6.6,6,6.6c1.7,0,6-0.7,6-6.6c0-5.9-4.4-6.6-6-6.6
            C109.2,149.1,104.8,149.8,104.8,155.7L104.8,155.7z"
			/>
			<path
				d="M129.4,157.6c0,3.3-2,4.7-5.2,4.7c-1.2,0-2.9-0.3-4-1.5c-0.7-0.8-0.9-1.8-1-3v-8.3h2.7v8.1
            c0,1.8,1,2.5,2.2,2.5c1.8,0,2.5-0.9,2.5-2.4v-8.3h2.7V157.6L129.4,157.6z"
			/>
			<path
				d="M134.2,158.1c0,0.7,0.4,2,2.7,2c1.2,0,2.6-0.3,2.6-1.6c0-1-0.9-1.2-2.3-1.6l-1.3-0.3c-2-0.5-4-0.9-4-3.7
            c0-1.4,0.8-3.9,4.8-3.9c3.8,0,4.9,2.5,4.9,4.1H139c-0.1-0.6-0.3-1.9-2.6-1.9c-1,0-2.2,0.4-2.2,1.5c0,1,0.8,1.2,1.3,1.3l3.1,0.8
            c1.7,0.4,3.3,1.1,3.3,3.4c0,3.8-3.8,4.1-4.9,4.1c-4.6,0-5.4-2.6-5.4-4.2H134.2L134.2,158.1z"
			/>
			<polygon points="146.7,161.9 144.1,161.9 144.1,149.4 146.7,149.4 146.7,161.9 	" />
			<polygon
				points="157.2,149.4 159.6,149.4 159.6,161.9 157,161.9 151.9,153 151.9,153 151.9,161.9 149.4,161.9 
            149.4,149.4 152.2,149.4 157.2,158.1 157.2,158.1 157.2,149.4 	"
			/>
			<path
				d="M168.4,155.2h5.2v6.8h-1.7l-0.3-1.6c-0.7,0.8-1.6,1.9-3.9,1.9c-3,0-5.8-2.2-5.8-6.6c0-3.4,1.9-6.7,6.1-6.6
            c3.9,0,5.4,2.5,5.5,4.2H171c0-0.5-0.9-2.1-2.7-2.1c-1.9,0-3.6,1.3-3.6,4.5c0,3.4,1.9,4.3,3.7,4.3c0.6,0,2.5-0.2,3-2.8h-2.9V155.2
            L168.4,155.2z"
			/>
			<path
				d="M27.3,174.7c0-3.5,2-4.4,3.4-4.4c1.4,0,3.4,0.9,3.4,4.4c0,3.5-2,4.4-3.4,4.4C29.2,179.1,27.3,178.2,27.3,174.7
            L27.3,174.7z M24.6,174.7c0,5.9,4.4,6.6,6,6.6c1.7,0,6-0.7,6-6.6c0-5.9-4.4-6.6-6-6.6C29,168.1,24.6,168.8,24.6,174.7L24.6,174.7z"
			/>
			<path
				d="M43.9,174.3v-3.7H46c1.6,0,2.3,0.5,2.3,1.7c0,0.6,0,2-2,2H43.9L43.9,174.3z M43.9,176.5h3c3.4,0,4-2.9,4-4
            c0-2.5-1.5-4-3.9-4h-5.7V181h2.6V176.5L43.9,176.5z"
			/>
			<path
				d="M58,174.3v-3.7h2.1c1.6,0,2.3,0.5,2.3,1.7c0,0.6,0,2-2,2H58L58,174.3z M58,176.5h3c3.4,0,4-2.9,4-4
            c0-2.5-1.5-4-3.9-4h-5.7V181H58V176.5L58,176.5z"
			/>
			<path
				d="M71.6,174.7c0-3.5,2-4.4,3.4-4.4c1.4,0,3.4,0.9,3.4,4.4c0,3.5-2,4.4-3.4,4.4C73.5,179.1,71.6,178.2,71.6,174.7
            L71.6,174.7z M68.9,174.7c0,5.9,4.4,6.6,6,6.6c1.7,0,6-0.7,6-6.6c0-5.9-4.4-6.6-6-6.6C73.3,168.1,68.9,168.8,68.9,174.7L68.9,174.7
            z"
			/>
			<path
				d="M88.1,174v-3.4h3.3c1.5,0,1.8,1,1.8,1.6c0,1.2-0.7,1.7-2,1.7H88.1L88.1,174z M85.5,181h2.6v-4.9h2.7
            c1.9,0,2,0.7,2,2.4c0,1.3,0.1,1.9,0.3,2.5h2.9v-0.3c-0.6-0.2-0.6-0.7-0.6-2.5c0-2.3-0.6-2.7-1.6-3.2c1.2-0.4,1.9-1.6,1.9-3
            c0-1.1-0.6-3.5-3.9-3.5h-6.3V181L85.5,181z"
			/>
			<polygon
				points="105.6,181 103,181 103,170.7 99.2,170.7 99.2,168.5 109.4,168.5 109.4,170.7 105.6,170.7 105.6,181 	
            "
			/>
			<path
				d="M123.5,176.6c0,3.3-2,4.7-5.2,4.7c-1.1,0-2.9-0.3-4-1.5c-0.7-0.8-0.9-1.8-1-3v-8.3h2.7v8.1
            c0,1.7,1,2.5,2.2,2.5c1.8,0,2.5-0.9,2.5-2.4v-8.3h2.7V176.6L123.5,176.6z"
			/>
			<polygon
				points="136.2,168.5 138.6,168.5 138.6,181 136,181 130.9,172.1 130.8,172.1 130.8,181 128.4,181 128.4,168.5 
            131.1,168.5 136.1,177.2 136.2,177.2 136.2,168.5 	"
			/>
			<polygon points="146,181 143.4,181 143.4,168.5 146,168.5 146,181 	" />
			<polygon
				points="156.2,181 153.6,181 153.6,170.7 149.8,170.7 149.8,168.5 160,168.5 160,170.7 156.2,170.7 156.2,181 
                "
			/>
			<polygon
				points="169.9,181 167.3,181 167.3,176.3 163,168.5 166.1,168.5 168.7,173.9 171.2,168.5 174.1,168.5 
            169.9,176.3 169.9,181 	"
			/>
			<path
				d="M99.4,18.7L14.9,60.3v19.5h9.5v58.6h149.4V79.8h11.4V60.3L99.4,18.7L99.4,18.7z M155.5,120.8H42.6V67.2
            l56.7-29l56.1,29V120.8L155.5,120.8z"
			/>
			<polygon points="125.2,86.1 72.9,86.1 72.9,67.2 125.2,67.2 125.2,86.1 	" />
			<polygon points="125.2,113.2 72.9,113.2 72.9,94.3 125.2,94.3 125.2,113.2 	" />
			<path
				d="M192.6,200H7.4c-4.1,0-7.4-3.3-7.4-7.4V7.4C0,3.3,3.3,0,7.4,0h185.2c4.1,0,7.4,3.3,7.4,7.4v185.2
            C200,196.7,196.7,200,192.6,200z M7.4,3C4.9,3,3,4.9,3,7.4v185.2c0,2.4,2,4.4,4.4,4.4h185.2c2.4,0,4.4-2,4.4-4.4V7.4
            c0-2.4-2-4.4-4.4-4.4H7.4z"
			/>
		</g>
	</svg>
</template>





<script>
export default {
	name: 'EHOLogo',
	props: {
		fill: {
			type: String,
			required: false,
			default: 'currentColor'
		}
	}
};
</script>