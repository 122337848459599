<template functional>
	<svg viewBox="0 0 18 10.4" :class="[data.class, data.staticClass]" :style="[data.style, data.staticStyle]">
		<g>
			<path fill="#325F8B" d="M13.3,3.5c0.1,0.5,0.2,0.9,0.2,1.7h3L17,3.5H13.3z" />
			<path
				fill="#325F8B"
				d="M5.5,2c-0.1,0.7,0,1.5,0.7,2.1C6.9,4.7,8.2,5,9,5.6c0.8,0.6,1.3,1.4,1.1,2.3C10,8.8,9.1,9.8,8,10.1
		s-2.5,0.1-2.6,0.1s1.3,0.3,2.5,0.2c1.3-0.1,2.5-0.7,3.4-1.5c1-0.8,1.5-1.9,1.7-3.1s-0.1-2.4-0.7-3.4c-0.6-1-1.5-1.7-2.4-2
		C9.1,0,8.3,0,7.7,0s-1,0.2-1.4,0.5C5.9,0.9,5.6,1.4,5.5,2L5.5,2z"
			/>
			<path fill="#5DC9F4" d="M2.9,0.1h2L2,10.3H0L2.9,0.1z" />
			<path fill="#325F8B" d="M10.6,0.1c1,0.5,1.3,0.9,2,1.7h4.9L18,0.1H10.6z" />
		</g>
	</svg>
</template>





<script>
export default {
	name: 'DFMark'
};
</script>